// Import Modules
import { Divider, Box, Typography } from "@material-ui/core";

// Import files
import Booking from "./screens/Booking/";
import "./App.css";
import Navigation from "./components/Navigation";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Divider className={"divider"} />
      <Booking />
      <Box ml={15} mr={15}>
        <Divider light />
      </Box>
      <Box mt={2}>
        <Typography className={"typography"}>
          &copy;{" "}{new Date().getFullYear()}{" "}
          - Performance Auto Carrier Inc. | 6487 Dixie Road, Unit 32
          Mississauga, ON Canada L5T 2K2 | Phone: 905-497-7770
        </Typography>
      </Box>
    </div>
  );
}

export default App;
