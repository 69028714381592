export const styleRules = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    width: "75%",
    backgroundColor: "#f5f5f5",
  },
  h2: {
    fontSize: 38,
    fontWeight: 100,
    color: "#888",
  },
  boxContainer: {
    width: '100%',
    textAlign: "left",
  },
  formControl: {
    backgroundColor: "white",
    minWidth: 120,
  },
  select: {
    padding: "15px 8px",
    minWidth: 250,
    borderRadius: 5,
    border: "1px solid #ccc",
  },
  danger: {
    color: "red",
  },
  continueButton: {
    backgroundColor: "#5CB85C",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#5CB85C",
      color: "#fff"
    }
  },
});
