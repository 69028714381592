export const styleRules = (theme) => ({
  select: {
    padding: "15px 8px",
    width: 'auto',
    borderRadius: 5,
    border: "1px solid #ccc",
  },
  gridItem: {
    width: '100%'
  },
  textField: {
    marginTop: 5,
    width: '100%',
  },
  bg: {
    backgroundColor: '#fff'
  },
  menu: {
    width: '100%',
  },
  typography: {
    color: '#888',
    fontSize: 16
  },
})