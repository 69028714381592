// Import Modules
import React from "react";
import { Paper, Box, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { isMobile } from "react-device-detect";

// Import Files
import { styleRules } from "./styles";
import SelectItem from "../../SelectItem/SelectItem";
import CustomInput from "../../CustomInput/CustomInput";
import booking from "../../../utils/booking.json";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Payment = (props) => {
  // Create style class
  const classes = useStyles();

  // Define Formik Form Initial Value, Validations, submit
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      paymentMethod: props.form.paymentMethod ?? "",
      nameOnCard: props.form.nameOnCard ?? "",
      cardNumber: props.form.cardNumber ?? "",
      cvv: props.form.cvv ?? "",
      month: props.form.month ?? "january",
      year: props.form.year ?? "2022"
    },
    validationSchema: yup.object().shape({
      paymentMethod: yup.string().required("Please select one option"),
      nameOnCard: yup.string().when("paymentMethod", {
        is: "credit_card",
        then: yup.string().required("Name on Card is required.")
      }),
      cardNumber: yup.string().when("paymentMethod", {
        is: "credit_card",
        then: yup.string().required("Credit Card Number is required.")
      }),
      cvv: yup.string().when("paymentMethod", {
        is: "credit_card",
        then: yup.string().required("CVV number must be between 3 to 4 digits number.")
      }),
      month: yup.string().when("paymentMethod", {
        is: "credit_card",
        then: yup.string().required("Expiry Date is required.")
      }),
      year: yup.string().when("paymentMethod", {
        is: "credit_card",
        then: yup.string().required("Expiry Date is required.")
      })
    }),
    onSubmit: (values) => {
      props.setForm({ ...props.form, ...values });
      props.setStep((step) => step + 1);
    },
  });

  // Get Options
  const options = booking.payment_options;
  const years = booking.years;
  const months = booking.months;

  return (
    <form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Paper elevation={0} variant="outlined" className={classes.paper}>
          <Box mt={3} ml={4}>
            <Typography variant="h2" className={classes.h2}>
              Payment
            </Typography>
          </Box>

          <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <SelectItem
                  fieldName={"How would you like to pay? *"}
                  id="paymentMethod"
                  name="paymentMethod"
                  options={options}
                  value={formik.values.paymentMethod}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched?.paymentMethod &&
                    !!formik.errors.paymentMethod
                  }
                  helperText={
                    !!formik.touched?.paymentMethod &&
                    formik.errors.paymentMethod
                  }
                  required
                />
              </Grid>
            </Grid>
          </Box>
          {formik.values.paymentMethod === "email_transfer" && (
            <React.Fragment>
              <Box ml={ isMobile ? 2 : 8 } mb={2}>
                <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography className={classes.boldTypo}>
                      Interac Email Address{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.typo}>
                      pay@performanceautocarrier.com
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box ml={ isMobile ? 2 : 8 } mb={2}>
                <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography className={classes.boldTypo}>
                      Interac Security Question{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.typo}>
                      Why Choose Performance Auto Carrier?
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Box ml={ isMobile ? 2 : 8 } mb={5}>
                <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography className={classes.boldTypo}>
                      Interac Security Que Answer{" "}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography>:</Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.typo}>
                      customer service
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          )}

          {formik.values.paymentMethod === "credit_card" && (
            <React.Fragment>
              <Box mt={1} ml={6}>
                <Box mb={0.5}>
                  <Typography className={classes.typo}>
                    Credit card Type
                  </Typography>
                </Box>
                <Grid
                  container
                  direction={"row"}
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <img src="/visa.png" alt={"Visa"} className={classes.img} />
                  </Grid>
                  <Grid item>
                    <img
                      src="/mastercard.png"
                      alt={"Master Card"}
                      className={classes.img}
                    />
                  </Grid>
                  <Grid item>
                    <img
                      src="/americanexpress.png"
                      alt={"American Express"}
                      className={classes.img}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"nameOnCard"}
                      fieldName={"Name on Card *"}
                      id={"nameOnCard"}
                      required
                      value={formik.values.nameOnCard}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Name on your card"}
                      error={
                        !!formik.touched?.nameOnCard &&
                        !!formik.errors.nameOnCard
                      }
                      helperText={
                        !!formik.touched?.nameOnCard && formik.errors.nameOnCard
                      }
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={9} md={9} lg={9}>
                      <CustomInput
                        fullWidth
                        variant="outlined"
                        name={"cardNumber"}
                        fieldName={"Card Number *"}
                        id={"cardNumber"}
                        required
                        value={formik.values.cardNumber}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder={"First Name"}
                        error={
                          !!formik.touched?.cardNumber &&
                          !!formik.errors.cardNumber
                        }
                        helperText={
                          !!formik.touched?.cardNumber &&
                          formik.errors.cardNumber
                        }
                      />
                    </Grid>
                    <Grid item>
                      <Box mt={3} ml={3}>
                        <img
                          src="/creditcard.png"
                          alt={"Credit Card"}
                          className={classes.img}
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6} mb={4}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"cvv"}
                      fieldName={"CVV *"}
                      id={"cvv"}
                      required
                      value={formik.values.cvv}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"CVV"}
                      error={!!formik.touched?.cvv && !!formik.errors.cvv}
                      helperText={!!formik.touched?.cvv && formik.errors.cvv}
                    />
                  </Grid>
                  <Grid
                    container
                    item
                    alignItems="center"
                    justifyContent="space-between"
                    xs={12}
                    sm={6}
                    md={6}
                    lg={6}
                  >
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                    <SelectItem
                        fieldName={"Expiry Date *"}
                        id="month"
                        name="month"
                        options={months}
                        value={formik.values.month}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          !!formik.touched?.month && !!formik.errors.month
                        }
                        helperText={
                          !!formik.touched?.month && formik.errors.month
                        }
                        required
                      />
                    </Grid>
                    <Grid item xs={12} sm={5} md={5} lg={5}>
                      <SelectItem
                        fieldName={<br />}
                        id="year"
                        name="year"
                        options={years}
                        value={formik.values.year}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={
                          !!formik.touched?.year && !!formik.errors.year
                        }
                        helperText={
                          !!formik.touched?.year && formik.errors.year
                        }
                        required
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Box mt={3} mb={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={props.goToPreviousStep}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                type={"submit"}
                variant="outlined"
                className={classes.continueButton}
                disabled={!formik.isValid}
              >
                Review
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </form>
  );
};

export default Payment;
