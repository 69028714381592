export const styleRules = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    width: "75%",
    backgroundColor: "#f5f5f5",
  },
  h2: {
    fontSize: 38,
    fontWeight: 0,
    color: "#888",
  },
  alignRight: {
    textAlign: "right",
  },
  alignLeft: {
    textAlign: "left",
  },
  topMargin: {
    marginTop: 8,
  },
  typography: {
    textAlign: "left",
    fontSize: 23,
    color: "#888",
    fontWeight: 300,
  },
  keyTypography: {
    color: "#888",
    fontWeight: 600,
    fontSize: 16,
  },
  valueTypography: {
    color: "#888",
  },
  submitButton: {
    backgroundColor: "#5CB85C",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#5CB85C",
      color: "#fff"
    }
  },
});
