// Import Modules
import React from "react";
import { Grid, Paper, Box, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

// File Imports
import { styleRules } from "./styles";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Navigation = () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Paper elevation={0} className={classes.paper}>
        <Box>
          <Grid container direction="row" alignItems="center">
            <Grid item>
              <Link href="https://www.performanceautocarrier.com">
                <img src="logo.gif" alt={"logo"} className={classes.logo} />
              </Link>
            </Grid>
            <Grid item>
              <Link
                href="https://www.performanceautocarrier.com/about-us/"
                className={classes.link}
              >
                About us
              </Link>
            </Grid>
            <Grid item />
            <Grid>
              <Link
                href="https://www.performanceautocarrier.com/contact-us/"
                className={classes.link}
              >
                Contact Us
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </div>
  );
};

export default Navigation;
