export const styleRules = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: "auto",
    width: "75%",
    backgroundColor: "#f5f5f5",
    textAlign: "left",
  },
  h2: {
    fontSize: 38,
    fontWeight: 0,
    color: "#888",
  },
  typography: {
    color: "#888",
  },
  continueButton: {
    backgroundColor: "#5CB85C",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#5CB85C",
      color: "#fff"
    }
  },
  datePicketContainer: {
    width: "100%",
    backgroundColor: "#fff",
    paddingLeft: 10,
    paddingBottom: 5,
  },
});
