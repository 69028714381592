// Import Modules
import React from "react";
import { Paper, Box, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useFormik } from "formik";
import * as yup from "yup";
import { isMobile } from "react-device-detect";

// Import Files
import { styleRules } from "./styles";
import SelectItem from "../../SelectItem/SelectItem";
import CustomInput from "../../CustomInput/CustomInput";
import booking from "../../../utils/booking.json";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Deliver = (props) => {
  // Declare style class
  const classes = useStyles();

  // Get options
  const countries = booking.countries;
  const options = booking.deliverLocations;
  const provinces = booking.states;
  const terminalLocations = booking.terminal_locations;

  // Define Formik Form Initial Value, Validations, submit
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      deliverTo: props.form.deliverTo ?? "",
      dlTerminalLocation: props.form.dlTerminalLocation ?? "",
      dlLocationName: props.form.dlLocationName ?? "",
      dlAddress: props.form.dlAddress ?? "",
      dlCity: props.form.dlCity ?? "",
      dlProvince: props.form.dlProvince ?? "",
      dlPostalCode: props.form.dlPostalCode ?? "",
      dlCountry: props.form.dlCountry ?? "canada",
    },
    validationSchema: yup.object().shape({
      deliverTo: yup.string().required("Please select one option"),
      dlTerminalLocation: yup.string().when("deliverTo", {
        is: "terminal_location",
        then: yup.string().required("Please select terminal location"),
      }),
      dlLocationName: yup.string().when("deliverTo", {
        is: "your_pickup_location",
        then: yup.string().required("Location Name is required"),
      }),
      dlAddress: yup.string().required("Address is required"),
      dlCity: yup.string().required("City is required"),
      dlProvince: yup.string().required("Province/State is required"),
      dlPostalCode: yup.string().required("Postal/Zip Code is required"),
      dlCountry: yup.string().required("Country is required"),
    }),
    onSubmit: (values) => {
      props.setForm({ ...props.form, ...values });
      props.setStep((step) => step + 1);
    },
  });

  // Update address fields based on a selected terminal location
  const handleTerminalLocation = (e) => {
    const value = e.target.value;
    const location = terminalLocations.find((tl) => tl.value === value);
    formik.handleChange(e);
    formik.setFieldValue("dlAddress", location.address);
    formik.setFieldValue("dlCity", location.city);
    formik.setFieldValue("dlProvince", location.province);
    formik.setFieldValue("dlPostalCode", location.postal);
    formik.setFieldValue("dlCountry", "canada");
  };

  // Update moving from and reset address fields
  const handleDeliverTo = async (e) => {
    formik.handleChange(e);
    formik.setFieldValue("dlTerminalLocation", "");
    formik.setFieldValue("dlLocationName", "");
    formik.setFieldValue("dlAddress", "");
    formik.setFieldValue("dlCity", "");
    formik.setFieldValue("dlProvince", "");
    formik.setFieldValue("dlPostalCode", "");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Paper elevation={0} variant="outlined" className={classes.paper}>
          <Box mt={3} ml={4}>
            <Typography variant="h2" className={classes.h2}>
              Deliver to
            </Typography>
          </Box>
          <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} md={6}>
                <SelectItem
                  fieldName={"Select Moving From *"}
                  id="deliverTo"
                  name="deliverTo"
                  options={options}
                  value={formik.values.deliverTo}
                  onChange={(e) => handleDeliverTo(e)}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched?.deliverTo && !!formik.errors.deliverTo
                  }
                  helperText={
                    !!formik.touched?.deliverTo && formik.errors.deliverTo
                  }
                  required
                />
              </Grid>
            </Grid>
          </Box>

          {/* If User select the terminal Location */}
          {formik.values.deliverTo === "terminal_location" && (
            <React.Fragment>
              <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} md={12}>
                    <SelectItem
                      fieldName={"Select Terminal Location *"}
                      id="dlTerminalLocation"
                      name="dlTerminalLocation"
                      options={terminalLocations}
                      value={formik.values.dlTerminalLocation}
                      onChange={(e) => handleTerminalLocation(e)}
                      onBlur={formik.handleBlur}
                      error={
                        !!formik.touched?.dlTerminalLocation &&
                        !!formik.errors.dlTerminalLocation
                      }
                      helperText={
                        !!formik.touched?.dlTerminalLocation &&
                        formik.errors.dlTerminalLocation
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
              {formik.values.dlTerminalLocation &&
                formik.values.dlTerminalLocation !== "" && (
                  <React.Fragment>
                    <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={12}>
                          <CustomInput
                            fullWidth
                            variant="outlined"
                            name={"dlAddress"}
                            fieldName={"Address *"}
                            id={"dlAddress"}
                            required
                            value={formik.values.dlAddress}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={6}>
                          <CustomInput
                            fullWidth
                            variant="outlined"
                            name={"dlCity"}
                            fieldName={"City *"}
                            id={"dlCity"}
                            required
                            value={formik.values.dlCity}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <SelectItem
                            fieldName={"Province/State *"}
                            id="dlProvince"
                            name="dlProvince"
                            options={provinces}
                            value={formik.values.dlProvince}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              !!formik.touched?.dlProvince &&
                              !!formik.errors.dlProvince
                            }
                            helperText={
                              !!formik.touched?.dlProvince &&
                              formik.errors.dlProvince
                            }
                            required
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={6}>
                          <CustomInput
                            fullWidth
                            variant="outlined"
                            name={"dlPostalCode"}
                            fieldName={"Postal/Zip Code *"}
                            id={"dlPostalCode"}
                            required
                            value={formik.values.dlPostalCode}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <SelectItem
                            fieldName={"Country *"}
                            id="dlCountry"
                            name="dlCountry"
                            options={countries}
                            value={formik.values.dlCountry}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={
                              !!formik.touched?.dlCountry &&
                              !!formik.errors.dlCountry
                            }
                            helperText={
                              !!formik.touched?.dlCountry &&
                              formik.errors.dlCountry
                            }
                            required
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </React.Fragment>
                )}
            </React.Fragment>
          )}

          {formik.values.deliverTo === "your_location" && (
            <React.Fragment>
              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={12}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"dlLocationName"}
                      fieldName={"Location Name *"}
                      id={"dlLocationName"}
                      required
                      value={formik.values.dlLocationName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Location Name"}
                      error={
                        !!formik.touched?.dlLocationName && !!formik.errors.dlLocationName
                      }
                      helperText={
                        !!formik.touched?.dlLocationName && formik.errors.dlLocationName
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={12}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"dlAddress"}
                      fieldName={"Address *"}
                      id={"dlAddress"}
                      required
                      value={formik.values.dlAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Address"}
                      error={
                        !!formik.touched?.dlAddress && !!formik.errors.dlAddress
                      }
                      helperText={
                        !!formik.touched?.dlAddress && formik.errors.dlAddress
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"dlCity"}
                      fieldName={"City *"}
                      id={"dlCity"}
                      required
                      value={formik.values.dlCity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"City"}
                      error={!!formik.touched?.dlCity && !!formik.errors.dlCity}
                      helperText={
                        !!formik.touched?.dlCity && formik.errors.dlCity
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <SelectItem
                      fieldName={"Province/State *"}
                      id="dlProvince"
                      name="dlProvince"
                      options={provinces}
                      value={formik.values.dlProvince}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!formik.touched?.dlProvince &&
                        !!formik.errors.dlProvince
                      }
                      helperText={
                        !!formik.touched?.dlProvince && formik.errors.dlProvince
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"dlPostalCode"}
                      fieldName={"Postal/Zip Code *"}
                      id={"dlPostalCode"}
                      required
                      value={formik.values.dlPostalCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Postal/Zip Code"}
                      error={
                        !!formik.touched?.dlPostalCode &&
                        !!formik.errors.dlPostalCode
                      }
                      helperText={
                        !!formik.touched?.dlPostalCode &&
                        formik.errors.dlPostalCode
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <SelectItem
                      fieldName={"Country *"}
                      id="dlCountry"
                      name="dlCountry"
                      options={countries}
                      value={formik.values.dlCountry}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!formik.touched?.dlCountry && !!formik.errors.dlCountry
                      }
                      helperText={
                        !!formik.touched?.dlCountry && formik.errors.dlCountry
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Box mt={3} mb={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={props.goToPreviousStep}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                type={"submit"}
                variant="outlined"
                className={classes.continueButton}
                disabled={!formik.isValid}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </form>
  );
};

export default Deliver;
