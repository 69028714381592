// Import Modules
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Grid,
  Box,
  Button,
  Typography,
  Divider,
} from "@material-ui/core";

// File Imports
import { styleRules } from "./styles";
import booking from "../../../utils/booking.json";
import Address from "./Address";
import { sendMail } from "../../../actions/sendEmails";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Review = (props) => {
  // Created style class variable
  const classes = useStyles();

  // Import form variable from prop
  let form = props.form;

  return (
    <React.Fragment>
      <Paper elevation={0} variant="outlined" className={classes.paper}>
        <Box mt={3} ml={4}>
          <Typography variant="h2" className={classes.h2}>
            Review/Submit
          </Typography>
        </Box>

        {/* Booking Service Details */}
        <Box mt={3} ml={5}>
          <Typography variant="h2" className={classes.typography}>
            Booking Service
          </Typography>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>
        <Box ml={3}>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Booking Service :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.bookingService === "truck_booking"
                    ? "Truck"
                    : form.bookingService === "rail_booking"
                    ? "Rail"
                    : ""}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>

        {/* Contact Details */}
        <Box mt={3} ml={5}>
          <Typography variant="h2" className={classes.typography}>
            Contact Information
          </Typography>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>
        <Box ml={1} mt={2}>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  First Name :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.firstName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} lg={6} spacing={2}>
              <Grid item xs={12} md={4} lg={4} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Last Name :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.lastName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Phone :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.phone}
                </Typography>
              </Grid>
            </Grid>
            <Grid item container xs={12} md={6} lg={6} spacing={2}>
              <Grid item xs={12} md={4} lg={4} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Mobile :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.mobile}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Email :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.email}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Address
            address={form.address}
            city={form.city}
            state={form.province}
            zip={form.postalCode}
            country={form.country}
          />
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>

        {/* Pickup Details */}
        <Box mt={3} ml={5}>
          <Typography variant="h2" className={classes.typography}>
            (Pickup) Moving From
          </Typography>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>
        <Box ml={1} mt={2}>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Moving From :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {booking.pickupLocations.find(
                    (location) => location.value === form.movingFrom
                  )?.label || form.movingFrom}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Terminal Location :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {booking.terminal_locations.find(
                    (location) => location.value === form.mvTerminalLocation
                  )?.label || form.mvLocationName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Address
            address={form.mvAddress}
            city={form.mvCity}
            state={form.mvProvince}
            zip={form.mvPostalCode}
            country={form.mvCountry}
          />

          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Pickup Date :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.pickupDate
                    ? new Date(form.pickupDate).toLocaleDateString()
                    : new Date().toLocaleDateString()}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>

        {/* Deliver Details */}
        <Box mt={3} ml={5}>
          <Typography variant="h2" className={classes.typography}>
            Deliver To
          </Typography>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>
        <Box ml={1} mt={2}>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Deliver To :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {booking.deliverLocations.find(
                    (location) => location.value === form.deliverTo
                  )?.label || form.deliverTo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Terminal Location :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {booking.terminal_locations.find(
                    (location) => location.value === form.dlTerminalLocation
                  )?.label || form.dlLocationName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <Address
            address={form.dlAddress}
            city={form.dlCity}
            state={form.dlProvince}
            zip={form.dlPostalCode}
            country={form.dlCountry}
          />
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>

        {/* Additional Details */}
        <Box mt={3} ml={5}>
          <Typography variant="h2" className={classes.typography}>
            Additional Info
          </Typography>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>
        <Box ml={1} mt={2}>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Is storage required? :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}></Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  where you found us? :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  Realtor
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Comments :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>

        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>

        {/* Payment Details */}
        <Box mt={3} ml={5}>
          <Typography variant="h2" className={classes.typography}>
            Payment Information
          </Typography>
        </Box>
        <Box mt={2} mb={2} ml={5} mr={5}>
          <Divider light />
        </Box>
        <Box ml={1} mt={2}>
          <Grid
            container
            direction={"row"}
            justifyContent="flex-start"
            alignItems="center"
            className={classes.topMargin}
          >
            <Grid item container xs={12} md={5} lg={5} spacing={2}>
              <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
                <Typography className={classes.keyTypography}>
                  Select Payment Option :
                </Typography>
              </Grid>
              <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
                <Typography className={classes.valueTypography}>
                  {form.paymentMethod === "credit_card"
                    ? "Credit Card"
                    : booking.payment_options.find(
                        (mode) => mode.value === form.paymentMethod
                      )?.label}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box mt={3} mb={3}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="default"
              onClick={props.goToPreviousStep}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              className={classes.submitButton}
              onClick={async () => await sendMail(form)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>
    </React.Fragment>
  );
};

export default Review;
