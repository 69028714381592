// Import Modules
import React from "react";
import { Paper, Box, Typography, Grid, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MomentUtils from "@date-io/moment";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { useFormik } from "formik";
import * as yup from "yup";
import { isMobile } from "react-device-detect";

// Import Files
import { styleRules } from "./styles";
import SelectItem from "../../SelectItem/SelectItem";
import CustomInput from "../../CustomInput/CustomInput";
import booking from "../../../utils/booking.json";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Pickup = (props) => {
  // Declare style class
  const classes = useStyles();

  // Get options
  const options = booking.pickupLocations;
  const provinces = booking.states;
  const countries = booking.countries;
  const terminalLocations = booking.terminal_locations;

  // Define Formik Form Initial Value, Validations, submit
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      movingFrom: props.form.movingFrom ?? "",
      mvTerminalLocation: props.form.mvTerminalLocation ?? "",
      mvLocationName: props.form.mvLocationName ?? "",
      mvAddress: props.form.mvAddress ?? "",
      mvCity: props.form.mvCity ?? "",
      mvProvince: props.form.mvProvince ?? "",
      mvPostalCode: props.form.mvPostalCode ?? "",
      mvCountry: props.form.mvCountry ?? "canada",
      pickupDate: props.form.pickupDate ?? "",
    },
    validationSchema: yup.object().shape({
      movingFrom: yup.string().required("Please select one option"),
      mvTerminalLocation: yup.string().when("movingFrom", {
        is: "terminal_location",
        then: yup.string().required("Please select terminal location"),
      }),
      mvLocationName: yup.string().when("movingFrom", {
        is: "your_pickup_location",
        then: yup.string().required("Location Name is required"),
      }),
      mvAddress: yup.string().required("Address is required"),
      mvCity: yup.string().required("City is required"),
      mvProvince: yup.string().required("Province/State is required"),
      mvPostalCode: yup.string().required("Postal/Zip Code is required"),
      mvCountry: yup.string().required("Country is required"),
    }),
    onSubmit: (values) => {
      props.setForm({ ...props.form, ...values });
      props.setStep((step) => step + 1);
    },
  });

  // Update address fields based on a selected terminal location
  const handleTerminalLocation = (e) => {
    const value = e.target.value;
    const location = terminalLocations.find((tl) => tl.value === value);
    formik.handleChange(e);
    formik.setFieldValue("mvLocationName", location.label);
    formik.setFieldValue("mvAddress", location.address);
    formik.setFieldValue("mvCity", location.city);
    formik.setFieldValue("mvProvince", location.province);
    formik.setFieldValue("mvPostalCode", location.postal);
    formik.setFieldValue("mvCountry", "canada");
  };

  // Update moving from and reset address fields
  const handleMovingFrom = async (e) => {
    formik.handleChange(e);
    formik.setFieldValue("mvTerminalLocation", "");
    formik.setFieldValue("mvLocationName", "");
    formik.setFieldValue("mvAddress", "");
    formik.setFieldValue("mvCity", "");
    formik.setFieldValue("mvProvince", "");
    formik.setFieldValue("mvPostalCode", "");
    formik.setFieldValue("pickupDate", "");
  };

  const onDateChange = (date) => {
    formik.setFieldValue("pickupDate", date);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Paper elevation={0} variant="outlined" className={classes.paper}>
          <Box mt={3} ml={4}>
            <Typography variant="h2" className={classes.h2}>
              (Pickup) Moving From
            </Typography>
          </Box>
          <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} md={6}>
                <SelectItem
                  fieldName={"Select Moving From *"}
                  id="movingFrom"
                  name="movingFrom"
                  options={options}
                  value={formik.values.movingFrom}
                  onChange={(e) => handleMovingFrom(e)}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched?.movingFrom && !!formik.errors.movingFrom
                  }
                  helperText={
                    !!formik.touched?.movingFrom && formik.errors.movingFrom
                  }
                  required
                />
              </Grid>
            </Grid>
          </Box>

          {/* If User select the terminal Location */}
          {formik.values.movingFrom === "terminal_location" && (
            <React.Fragment>
              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} md={12}>
                    <SelectItem
                      fieldName={"Select Terminal Location *"}
                      id="mvTerminalLocation"
                      name="mvTerminalLocation"
                      options={terminalLocations}
                      value={formik.values.mvTerminalLocation}
                      onChange={(e) => handleTerminalLocation(e)}
                      onBlur={formik.handleBlur}
                      error={
                        !!formik.touched?.mvTerminalLocation &&
                        !!formik.errors.mvTerminalLocation
                      }
                      helperText={
                        !!formik.touched?.mvTerminalLocation &&
                        formik.errors.mvTerminalLocation
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
              {formik.values.mvTerminalLocation &&
                formik.values.mvTerminalLocation !== "" && (
                  <React.Fragment>
                    <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={12}>
                          <CustomInput
                            fullWidth
                            variant="outlined"
                            name={"mvAddress"}
                            fieldName={"Address *"}
                            id={"mvAddress"}
                            required
                            value={formik.values.mvAddress}
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={6}>
                          <CustomInput
                            fullWidth
                            variant="outlined"
                            name={"mvCity"}
                            fieldName={"City *"}
                            id={"mvCity"}
                            required
                            value={formik.values.mvCity}
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <SelectItem
                            fieldName={"Province/State *"}
                            id="mvProvince"
                            name="mvProvince"
                            options={provinces}
                            value={formik.values.mvProvince}
                            required
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Box>

                    <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                      <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={4}
                      >
                        <Grid item xs={12} sm={6}>
                          <CustomInput
                            fullWidth
                            variant="outlined"
                            name={"mvPostalCode"}
                            fieldName={"Postal/Zip Code *"}
                            id={"mvPostalCode"}
                            value={formik.values.mvPostalCode}
                            required
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6}>
                          <SelectItem
                            fieldName={"Country *"}
                            id="mvCountry"
                            name="mvCountry"
                            options={countries}
                            value={formik.values.mvCountry}
                            disabled
                            required
                          />
                        </Grid>
                      </Grid>
                    </Box>
                    <Box ml={7} mr={6}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                          <Typography className={classes.typography}>
                            {"Pickup Date *"}
                          </Typography>
                          <Box
                            className={classes.datePicketContainer}
                          >
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                              <KeyboardDatePicker
                                disableToolbar
                                variant="inline"
                                format="DD/MM/yyyy"
                                margin="normal"
                                id="pickupDate"
                                name="pickupDate"
                                label=""
                                value={formik.values.pickupDate || new Date()}
                                onChange={(e) => onDateChange(e)}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputAdornmentProps={{ position: "start" }}
                              />
                            </MuiPickersUtilsProvider>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  </React.Fragment>
                )}
            </React.Fragment>
          )}

          {/* If user selects his own pickup location */}
          {formik.values.movingFrom === "your_pickup_location" && (
            <React.Fragment>
              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={12}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"mvLocationName"}
                      fieldName={"Location Name *"}
                      id={"mvLocationName"}
                      required
                      value={formik.values.mvLocationName}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Location Name"}
                      error={
                        !!formik.touched?.mvLocationName &&
                        !!formik.errors.mvLocationName
                      }
                      helperText={
                        !!formik.touched?.mvLocationName &&
                        formik.errors.mvLocationName
                      }
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={12}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"mvAddress"}
                      fieldName={"Address *"}
                      id={"mvAddress"}
                      required
                      value={formik.values.mvAddress}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Address"}
                      error={
                        !!formik.touched?.mvAddress && !!formik.errors.mvAddress
                      }
                      helperText={
                        !!formik.touched?.mvAddress && formik.errors.mvAddress
                      }
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"mvCity"}
                      fieldName={"City *"}
                      id={"mvCity"}
                      required
                      value={formik.values.mvCity}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"City"}
                      error={!!formik.touched?.mvCity && !!formik.errors.mvCity}
                      helperText={
                        !!formik.touched?.mvCity && formik.errors.mvCity
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <SelectItem
                      fieldName={"Province/State *"}
                      id="mvProvince"
                      name="mvProvince"
                      options={provinces}
                      value={formik.values.mvProvince}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!formik.touched?.mvProvince &&
                        !!formik.errors.mvProvince
                      }
                      helperText={
                        !!formik.touched?.mvProvince && formik.errors.mvProvince
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>

              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid
                  container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  spacing={4}
                >
                  <Grid item xs={12} sm={6}>
                    <CustomInput
                      fullWidth
                      variant="outlined"
                      name={"mvPostalCode"}
                      fieldName={"Postal/Zip Code *"}
                      id={"mvPostalCode"}
                      required
                      value={formik.values.mvPostalCode}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder={"Postal/Zip Code"}
                      error={
                        !!formik.touched?.mvPostalCode &&
                        !!formik.errors.mvPostalCode
                      }
                      helperText={
                        !!formik.touched?.mvPostalCode &&
                        formik.errors.mvPostalCode
                      }
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <SelectItem
                      fieldName={"Country *"}
                      id="mvCountry"
                      name="mvCountry"
                      options={countries}
                      value={formik.values.mvCountry}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      error={
                        !!formik.touched?.mvCountry && !!formik.errors.mvCountry
                      }
                      helperText={
                        !!formik.touched?.mvCountry && formik.errors.mvCountry
                      }
                      required
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Typography className={classes.typography}>
                      {"Pickup Date *"}
                    </Typography>
                    <Box
                      className={classes.datePicketContainer}
                    >
                      <MuiPickersUtilsProvider utils={MomentUtils}>
                        <KeyboardDatePicker
                          disableToolbar
                          variant="inline"
                          format="DD/MM/yyyy"
                          margin="normal"
                          id="pickupDate"
                          name="pickupDate"
                          label=""
                          value={formik.values.pickupDate || new Date()}
                          onChange={(e) => onDateChange(e)}
                          InputAdornmentProps={{ position: "start" }}
                        />
                      </MuiPickersUtilsProvider>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </React.Fragment>
          )}
        </Paper>
        <Box mt={3} mb={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button
                variant="outlined"
                color="default"
                onClick={props.goToPreviousStep}
              >
                Previous
              </Button>
            </Grid>
            <Grid item>
              <Button
                type="submit"
                variant="outlined"
                className={classes.continueButton}
                disabled={!formik.isValid}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </form>
  );
};

export default Pickup;
