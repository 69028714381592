// Module Imports
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Box,
  Typography,
  Grid,
  RadioGroup,
  Radio,
  FormControlLabel,
  Button,
} from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";
import { isMobile } from "react-device-detect";

// File Imports
import { styleRules } from "./styles";
import CustomInput from "../../CustomInput/CustomInput";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const VehicleInfo = (props) => {
  // Create style class
  const classes = useStyles();

  // Define Formik Form Initial Value, Validations, submit
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      vehicleMake: props.form.vehicleMake ?? "",
      vehicleModel: props.form.vehicleModel ?? "",
      vehicleYear: props.form.vehicleYear ?? "",
      vehicleVIN: props.form.vehicleVIN ?? "",
      vehicleColor: props.form.vehicleColor ?? "",
      vehiclePlateNo: props.form.vehiclePlateNo ?? "",
      vehicleRunning: props.form.vehicleRunning ?? "",
    },
    validationSchema: yup.object().shape({
      vehicleMake: yup.string().required("Vehicle Make is required"),
      vehicleModel: yup.string().required("Vehicle Model is required"),
      vehicleYear: yup.string().required("Vehicle Year is required"),
      vehicleVIN: yup.string().required("VIN is required"),
    }),
    onSubmit: (values) => {
      props.setForm({ ...props.form, ...values });
      props.setStep((step) => step + 1);
    },
  });
  
  return (
    <form onSubmit={formik.handleSubmit}>
      <React.Fragment>
        <Paper elevation={0} variant="outlined" className={classes.paper}>
          <Box mt={3} ml={4}>
            <Typography variant="h2" className={classes.h2}>
              Vehicle Information
            </Typography>
          </Box>
          <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  variant="outlined"
                  name={"vehicleMake"}
                  fieldName={"Vehicle Make *"}
                  id={"vehicleMake"}
                  required
                  value={formik.values.vehicleMake}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Vehicle Make"}
                  error={
                    !!formik.touched?.vehicleMake && !!formik.errors.vehicleMake
                  }
                  helperText={
                    !!formik.touched?.vehicleMake && formik.errors.vehicleMake
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  fullWidth
                  variant="outlined"
                  name={"vehicleModel"}
                  fieldName={"Vehicle Model *"}
                  id={"vehicleModel"}
                  required
                  value={formik.values.vehicleModel}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Vehicle Make"}
                  error={
                    !!formik.touched?.vehicleModel &&
                    !!formik.errors.vehicleModel
                  }
                  helperText={
                    !!formik.touched?.vehicleModel && formik.errors.vehicleModel
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6}>
                <CustomInput
                  fullWidth
                  variant="outlined"
                  name={"vehicleYear"}
                  fieldName={"Vehicle Year *"}
                  id={"vehicleYear"}
                  required
                  value={formik.values.vehicleYear}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Vehicle Year"}
                  error={
                    !!formik.touched?.vehicleYear && !!formik.errors.vehicleYear
                  }
                  helperText={
                    !!formik.touched?.vehicleYear && formik.errors.vehicleYear
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  fullWidth
                  variant="outlined"
                  name={"vehicleVIN"}
                  fieldName={"VIN *"}
                  id={"vehicleVIN"}
                  required
                  value={formik.values.vehicleVIN}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"VIN"}
                  error={
                    !!formik.touched?.vehicleVIN && !!formik.errors.vehicleVIN
                  }
                  helperText={
                    !!formik.touched?.vehicleVIN && formik.errors.vehicleYear
                  }
                />
              </Grid>
            </Grid>
          </Box>

          <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  fullWidth
                  variant="outlined"
                  name={"vehicleColor"}
                  fieldName={"Çolor"}
                  id={"vehicleColor"}
                  value={formik.values.vehicleColor}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Color"}
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6} lg={6}>
                <CustomInput
                  fullWidth
                  variant="outlined"
                  name={"vehiclePlateNo"}
                  fieldName={"Plate No."}
                  id={"vehiclePlateNo"}
                  value={formik.values.vehiclePlateNo}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder={"Plate No."}
                />
              </Grid>
            </Grid>
          </Box>

          <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
            <Grid
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
              spacing={4}
            >
              <Grid item xs={12} sm={6}>
                <Typography className={classes.typography}>
                  Is vehicle running?
                </Typography>
                <RadioGroup
                  row
                  id="vehicleRunning"
                  name="vehicleRunning"
                  defaultValue="top"
                  onChange={formik.handleChange}
                >
                  <FormControlLabel
                    checked={formik.values.vehicleRunning === "yes"}
                    value={"yes"}
                    control={<Radio color="primary" />}
                    label="Yes"
                  />
                  <FormControlLabel
                    checked={formik.values.vehicleRunning === "no"}
                    value="no"
                    control={<Radio color="primary" />}
                    label="No"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Box>
        </Paper>
        <Box mt={3} mb={5}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button variant="outlined" color="default" onClick={props.goToPreviousStep}>
                Previous
              </Button>
            </Grid>
            <Grid item>
            <Button
                type="submit"
                variant="outlined"
                className={classes.continueButton}
                disabled={!formik.isValid}
              >
                Continue
              </Button>
            </Grid>
          </Grid>
        </Box>
      </React.Fragment>
    </form>
  );
};

export default VehicleInfo;
