// Module Imports
import React, { useState } from "react";
import { Grid, Typography, Box, Paper } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import ProgressBar from "@ramonak/react-progress-bar";

// File Imports
import { styleRules } from "./styles";
import BookingService from "../../components/Booking/BookingService";
import ContactInformation from "../../components/Booking/ContactInformation";
import Pickup from "../../components/Booking/Pickup";
import Deliver from "../../components/Booking/Deliver";
import VehicleInfo from "../../components/Booking/VehicleInfo";
import Payment from "../../components/Booking/Payment";
import Review from "../../components/Booking/Review";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Booking = () => {
  const classes = useStyles();

  // Initialize component states
  const [step, setStep] = useState(1);
  const [form, setForm] = useState({});

  // Update step
  const goToPreviousStep = async () => {
    await setStep(step - 1);
  };

  return (
    <div className={classes.root}>
      <Paper elevation={0} className={classes.paper}>
        {/* Steps Progressbar */}
        <Box mt={2} mb={4}>
          <ProgressBar
            completed={step}
            height="30px"
            maxCompleted={7}
            customLabel={`Step ${step} of 7`}
            borderRadius="5px"
            bgColor="#5CB85C"
            labelAlignment="center"
            baseBgColor="#F5F5F5"
            labelClassName={classes.label}
          />
        </Box>

        {/* Steps Menu */}
        <Box mt={4} mb={4}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid
              item
              className={step === 1 ? classes.activeGrid : classes.grid}
              onClick={() => {
                setStep(1);
              }}
            >
              <Typography>Booking Service</Typography>
            </Grid>
            <Grid
              item
              className={step === 2 ? classes.activeGrid : classes.grid}
              onClick={() => {
                if (step >= 3) setStep(2);
              }}
            >
              <Typography>Contact Information</Typography>
            </Grid>
            <Grid
              item
              className={step === 3 ? classes.activeGrid : classes.grid}
              onClick={() => {
                if (step >= 4) setStep(3);
              }}
            >
              <Typography>(Pick up) Moving From</Typography>
            </Grid>
            <Grid
              item
              className={step === 4 ? classes.activeGrid : classes.grid}
              onClick={() => {
                if (step >= 5) setStep(4);
              }}
            >
              <Typography>Deliver to</Typography>
            </Grid>
            <Grid
              item
              className={step === 5 ? classes.activeGrid : classes.grid}
              onClick={() => {
                if (step >= 6) setStep(5);
              }}
            >
              <Typography>Vehicle Information</Typography>
            </Grid>
            <Grid
              item
              className={step === 6 ? classes.activeGrid : classes.grid}
              onClick={() => {
                if (step >= 7) setStep(6);
              }}
            >
              <Typography>Payment</Typography>
            </Grid>
            <Grid
              item
              className={step === 7 ? classes.activeGrid : classes.grid}
              onClick={() => {
                if (step === 6) setStep(7);
              }}
            >
              <Typography>Review</Typography>
            </Grid>
          </Grid>
        </Box>
      </Paper>

      {/* Steps */}
      {step === 1 && (
        <BookingService form={form} setForm={setForm} setStep={setStep} />
      )}
      {step === 2 && (
        <ContactInformation
          form={form}
          setForm={setForm}
          setStep={setStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {step === 3 && (
        <Pickup
          form={form}
          setForm={setForm}
          setStep={setStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {step === 4 && (
        <Deliver
          form={form}
          setForm={setForm}
          setStep={setStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {step === 5 && (
        <VehicleInfo
          form={form}
          setForm={setForm}
          setStep={setStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {step === 6 && (
        <Payment
          form={form}
          setForm={setForm}
          setStep={setStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
      {step === 7 && (
        <Review
          form={form}
          setForm={setForm}
          setStep={setStep}
          goToPreviousStep={goToPreviousStep}
        />
      )}
    </div>
  );
};

export default Booking;
