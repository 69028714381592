export const styleRules = (theme) => ({
  root: {
    flexGrow: 1,
  },
  activeGrid: {
    backgroundColor: "#E4744F",
    color: "#fff",
    fontSize: 14,
    padding: "10px !important",
    borderRadius: 5,
    cursor: "pointer",
    marginRight: 8
  },
  grid: {
    padding: "10px !important",
    fontSize: 14,
    borderRadius: 5,
    cursor: "pointer",
    marginRight: 8,
    '&:hover': {
      backgroundColor: '#f0f0f0'
    }
  },
  paper: {
    margin: "auto",
    width: "78%",
  },
  label: {
    fontSize: 12,
    color: "#fff",
    fontWeight: 200,
  },
});
