export const styleRules = (theme) => ({
  root: {
    width: '100%',
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused": {
        backgroundColor: "#fff"
      }
    }
  },
  input: {
    padding: "7px 2px",
  },
  onFocus: {
    backgroundColor: '#fff'
  },
  typography: {
    color: "#888",
    fontSize: 16,
  },
});
