import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Typography } from "@material-ui/core";

// File Imports
import { styleRules } from "./styles";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const CustomInput = (props) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Typography className={classes.typography}>{props.fieldName}</Typography>
      <TextField
        variant='outlined'
        margin={"dense"}
        inputProps={{ autoComplete: "off" }}
        classes={{
          root: classes.root,
        }}
        InputProps={{
          className: classes.input,
        }}
        {...props}
      />
    </React.Fragment>
  );
};

export default CustomInput;
