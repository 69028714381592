// Import Modules
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Box, Typography, Grid, Button } from "@material-ui/core";
import { useFormik } from "formik";
import { isMobile } from "react-device-detect";
import * as yup from "yup";

// Import Files
import { styleRules } from "./styles";
import CustomInput from "../../CustomInput/CustomInput";
import SelectItem from "../../SelectItem/SelectItem";
import booking from "../../../utils/booking.json";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const ContactInformation = (props) => {
  // Create Style Class
  const classes = useStyles();

  // Get options
  const countries = booking.countries;
  const provinces = booking.states;

  // Define Formik Form Initial Values, Validations, Submit
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      firstName: props.form.firstName ?? "",
      lastName: props.form.lastName ?? "",
      phone: props.form.phone ?? "",
      mobile: props.form.mobile ?? "",
      email: props.form.email ?? "",
      address: props.form.address ?? "",
      city: props.form.city ?? "",
      province: props.form.province ?? "",
      postalCode: props.form.postalCode ?? "",
      country: props.form.country ?? "canada",
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("First Name is required"),
      lastName: yup.string().required("Last Name is required"),
      phone: yup.string().required("Phone is required"),
      email: yup.string().email("Invalid email").required("Email is required"),
      address: yup.string().required("Address is required"),
      city: yup.string().required("City is required"),
      province: yup.string().required("Province/State is required"),
      postalCode: yup.string().required("Postal/Zip Code is required"),
      country: yup.string().required("Country is required"),
    }),
    onSubmit: (values) => {
      props.setForm({ ...props.form, ...values });
      props.setStep((step) => step + 1);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper elevation={0} className={classes.paper}>
        <Box mt={3} ml={4}>
          <Typography variant="h2" className={classes.h2}>
            Contact Information
          </Typography>
        </Box>
        <Box mt={3} ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"firstName"}
                fieldName={"First Name *"}
                id={"firstName"}
                required
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"First Name"}
                error={!!formik.touched?.firstName && !!formik.errors.firstName}
                helperText={
                  !!formik.touched?.firstName && formik.errors.firstName
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"lastName"}
                fieldName={"Last Name *"}
                id={"lastName"}
                required
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Last Name"}
                error={!!formik.touched?.lastName && !!formik.errors.lastName}
                helperText={
                  !!formik.touched?.lastName && formik.errors.lastName
                }
              />
            </Grid>
          </Grid>
        </Box>

        <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"phone"}
                fieldName={"Phone *"}
                id={"phone"}
                required
                value={formik.values.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Phone"}
                error={!!formik.touched?.phone && !!formik.errors.phone}
                helperText={!!formik.touched?.phone && formik.errors.phone}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"mobile"}
                fieldName={"Mobile"}
                id={"mobile"}
                value={formik.values.mobile}
                onChange={formik.handleChange}
                placeholder={"Mobile"}
              />
            </Grid>
          </Grid>
        </Box>

        <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"email"}
                fieldName={"Email *"}
                id={"email"}
                required
                value={formik.values.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Email"}
                error={!!formik.touched?.email && !!formik.errors.email}
                helperText={!!formik.touched?.email && formik.errors.email}
              />
            </Grid>
          </Grid>
        </Box>

        <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={12}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"address"}
                fieldName={"Address *"}
                id={"address"}
                required
                value={formik.values.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Address"}
                error={!!formik.touched?.address && !!formik.errors.address}
                helperText={!!formik.touched?.address && formik.errors.address}
              />
            </Grid>
          </Grid>
        </Box>

        <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"city"}
                fieldName={"City *"}
                id={"city"}
                required
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"City"}
                error={!!formik.touched?.city && !!formik.errors.city}
                helperText={!!formik.touched?.city && formik.errors.city}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <SelectItem
                fieldName={"Province/State *"}
                id="province"
                name="province"
                options={provinces}
                value={formik.values.province}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched?.province && !!formik.errors.province}
                helperText={
                  !!formik.touched?.province && formik.errors.province
                }
                required
              />
            </Grid>
          </Grid>
        </Box>

        <Box ml={isMobile ? 0 : 6} mr={isMobile ? 0 : 6}>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={4}
          >
            <Grid item xs={12} sm={6}>
              <CustomInput
                fullWidth
                variant="outlined"
                name={"postalCode"}
                fieldName={"Postal/Zip Code *"}
                id={"postalCode"}
                required
                value={formik.values.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder={"Postal/Zip Code"}
                error={
                  !!formik.touched?.postalCode && !!formik.errors.postalCode
                }
                helperText={
                  !!formik.touched?.postalCode && formik.errors.postalCode
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6} lg={6}>
              <SelectItem
                fieldName={"Country *"}
                id="coutnry"
                name="coutnry"
                options={countries}
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={!!formik.touched?.country && !!formik.errors.country}
                helperText={!!formik.touched?.country && formik.errors.country}
                required
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
      <Box mt={3} mb={5}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Button
              variant="outlined"
              color="default"
              onClick={props.goToPreviousStep}
            >
              Previous
            </Button>
          </Grid>
          <Grid item>
            <Button
              type="submit"
              variant="outlined"
              className={classes.continueButton}
              disabled={!formik.isValid}
            >
              Continue
            </Button>
          </Grid>
        </Grid>
      </Box>
    </form>
  );
};

export default ContactInformation;
