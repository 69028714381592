export const styleRules = (theme) => ({
  container: {
    flexGrow: 1,
  },
  paper: {
    margin: "auto",
    width: "79.5%",
  },
  logo: {
    width: 200,
    height: 50,
    marginRight: 20,
  },
  link: {
    fontSize: 16,
    color: "#888",
    marginRight: 20,
    cursor: "pointer",
    "&:hover": {
      color: "#000",
      textDecoration: "none",
    },
  },
});
