export const styleRules = (theme) => ({
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    width: "75%",
    backgroundColor: '#f5f5f5',
    textAlign: 'left'
  },
  h2: {
    fontSize: 38,
    fontWeight: 0,
    color: "#888",
  },
  continueButton: {
    backgroundColor: "#5CB85C",
    color: "#fff",
    '&:hover': {
      backgroundColor: "#5CB85C",
      color: "#fff"
    }
  },
  typography: {
    color: '#888',
    fontSize: 16
  },
  textField: {
    minWidth: "100%",
    marginTop: 5
  },
})