// Imports Module
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography, Box, Button, Paper } from "@material-ui/core";
import { useFormik } from "formik";
import * as yup from "yup";

// Imports Files
import { styleRules } from "./styles";
import SelectItem from "../../SelectItem";
import booking from "../../../utils/booking.json";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const BookingService = (props) => {
  const classes = useStyles();

  // Get Options
  const options = booking.bookingServices;

  // Define Formik Form, Initial Values, Validations, Submit
  const formik = useFormik({
    validateOnMount: true,
    initialValues: {
      bookingService: props.form.bookingService ?? "",
    },
    validationSchema: yup.object().shape({
      bookingService: yup.string().required("Booking service is required"),
    }),
    onSubmit: (values) => {
      props.setForm({ ...props.form, ...values });
      props.setStep((step) => step + 1);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Paper elevation={0} variant="outlined" className={classes.paper}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
        >
          <Box mt={4} mb={2}>
            <Typography variant="h2" className={classes.h2}>
              Booking Service ::
            </Typography>
          </Box>
          <Box mt={1} mb={2} className={classes.boxContainer}>
            <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} md={4}>
                <Typography align="right">Select Booking Service:</Typography>
              </Grid>
              <Grid item xs={12} md={4} style={{ width: '100%' }}>
                <SelectItem
                  id="bookingService"
                  name="bookingService"
                  options={options}
                  value={formik.values.bookingService}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    !!formik.touched?.bookingService &&
                    !!formik.errors.bookingService
                  }
                  helperText={
                    !!formik.touched?.bookingService &&
                    formik.errors.bookingService
                  }
                  required
                />
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Paper>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        <Box mt={3} mb={3}>
          <Button
            type="submit"
            variant="outlined"
            className={classes.continueButton}
            disabled={!formik.isValid}
          >
            Continue
          </Button>
        </Box>
      </Grid>
    </form>
  );
};

export default BookingService;
