// Module Imports
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";

// File Imports
import { styleRules } from "./styles";

//Default const
const useStyles = makeStyles((theme) => styleRules(theme));

const Address = (props) => {
  const { address, city, state, zip, country } = props;
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid
        container
        direction={"row"}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.topMargin}
      >
        <Grid item container xs={12} md={5} lg={5} spacing={2}>
          <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
            <Typography className={classes.keyTypography}>Address :</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
            <Typography className={classes.valueTypography}>
              {address}
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={"row"}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.topMargin}
      >
        <Grid item container xs={12} md={5} lg={5} spacing={2}>
          <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
            <Typography className={classes.keyTypography}>City :</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
            <Typography className={classes.valueTypography}>
              {city}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} lg={6} spacing={2}>
          <Grid item xs={12} md={4} lg={4} className={classes.alignRight}>
            <Typography className={classes.keyTypography}>
              Province/State :
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
            <Typography className={classes.valueTypography}>{state}</Typography>
          </Grid>
        </Grid>
      </Grid>

      <Grid
        container
        direction={"row"}
        justifyContent="flex-start"
        alignItems="center"
        className={classes.topMargin}
      >
        <Grid item container xs={12} md={5} lg={5} spacing={2}>
          <Grid item xs={12} md={6} lg={6} className={classes.alignRight}>
            <Typography className={classes.keyTypography}>
              Postal/Zip Code :
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
            <Typography className={classes.valueTypography}>{zip}</Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12} md={6} lg={6} spacing={2}>
          <Grid item xs={12} md={4} lg={4} className={classes.alignRight}>
            <Typography className={classes.keyTypography}>Country :</Typography>
          </Grid>
          <Grid item xs={12} md={6} lg={6} className={classes.alignLeft}>
            <Typography className={classes.valueTypography}>{country}</Typography>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default Address;
